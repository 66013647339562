var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gx-app-layout ant-layout ant-layout-has-sider",
      attrs: { id: "root" },
    },
    [_c("router-view"), _c("notifications", { attrs: { group: "foo" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }